.about-us-main {
  /* padding-bottom: 5em; */
  .about-us-title {
    h3 {
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      color: #001d45;
      margin-block: 2em;
      
    }
  }
}
.capabilities-main {
  .cap-grp-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
  @media screen and (max-width: px) {

  }
    .cap-grp-item {
      width: 100%;
      .cap-grp-header {
        display: flex;
        align-items: center;
        gap: 1em;
        padding: 0.5em;
        margin-bottom: 0.5em;
        /* border: 1px solid blue; */
        h4 {
          font-size: 20px;
          line-height: 28px;
          color: #001d45;
        }
      }
      .img-stack {
        display: grid;
        gap: 0.5em;
        padding: 2em;
        width: 100%;

        img {
          width: 100%;
          margin: 0 auto;
          padding: 0;
          -o-object-fit: fill;
          object-fit: fill;
          display: block;
          height: 70px;
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
        }
      }
    }
  }
}

.services-main {
  padding-inline: 6em;
  padding-bottom: 5em;
}

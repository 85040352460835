.vision {
  background: linear-gradient(to right, #fff, #fef8df);
}
.card-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2em;
  justify-content: center;
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.searchbar {
  cursor: pointer;
}
.searchbar input::-moz-placeholder {
  color: #e5e5e5;
  font-size: 14px;
  line-height: 28px;
}

.searchbar input::-ms-input-placeholder {
  color: #e5e5e5;
  font-size: 14px;
  line-height: 28px;
}

.searchbar input::placeholder {
  color: #e5e5e5;
  font-size: 14px;
  line-height: 28px;
}
.insights-card-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2em;
  @media screen and (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }

}
.select-wrapper {
  padding-inline: 1em;
  select {
    padding-block: 1em;
  }
}
.select-wrapper:hover {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}


/* ScrollAnimatedDiv.css */
.scroll-animated-div {
  opacity: 0;
  // transform: translateX(0);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}



.animate-left{
  opacity: 1;
  position: relative;
  transform: translateX(0);
  overflow: hidden;
}
.animate-right{
  opacity: 1;
  position: relative;
  transform: translateX(0);
  overflow: hidden;
}
// .scm-cover-main {
//   padding-inline: 11em;
//   /* padding-bottom: 5em; */
// }
.scm-cover-title h3 {
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #001d45;
  margin-block: 2em;
}

// .about-us-main {
//   padding-inline: 11em;
//   /* padding-bottom: 5em; */
// }
.about-us-title h3 {
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #001d45;
  margin-block: 2em;
}
.fund-main {
  .about-the-fund {
    margin-block: 3em;
  }
}
.thesis {
  h3 {
    text-align: center;
    margin-block: 1em;
  }
  .thesis-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2em;
    @media screen and (max-width: 615px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 520px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
.investor-main {
  // padding-inline: 11em;
  // padding-bottom: 5em;
  .investors {
    
    p {
      font-size: 20px;
      line-height: 28px;
      margin-block: 0.5em;
    }
  }
}
tr {
  background-color: #001d45;
  color: #fff;
}
tr:nth-child(even) {
  background-color: #5dddff;
}
.logo-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 2em 4em;
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 640px) {
    padding: 2em 2em;
  }
  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
    
  }
  @media screen and (max-width: 460px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .logo-card.justify-start {
    justify-content: start !important;
  }
  .logo-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-block: 2em;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "EB Garamond", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul {
  list-style: none;
}
p {
  text-align: justify;
}

.carousel-root {
  height: 100%;
}

.carousel-slider {
  height: 100%;
}
.slider-wrapper {
  height: 100%;
}
.slider{
height: 100%;
}

.con {
  margin-right: auto;
  margin-left: auto;
  width: 1200px;

  // @media screen and (max-width: 1230px) {
  //   width: 990px;
  // }

  // @media screen and (max-width: 1024px) {
  //   width: 768px;
  // }

  // @media screen and (max-width: 768px) {
  //   width: 100%;
  // }
}
